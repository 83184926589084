import React, { Component } from 'react'
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl'
import { propTypes } from '../../../util/types'
import * as custom from '../../../marketplace-custom-config.js'

import { FieldTextInput, FieldSelect } from '../../../components'
import css from '../EditListingFeaturesForm.module.css'
import EditListingTitleForm from './EditListingTitleForm'

class EditAVTechFeaturesComponent extends Component {
  render() {
    const { intl } = this.props

    return (
      <div>
        <EditListingTitleForm />
        <div className={css.listingSectionContainer}>
          <h2>
            <FormattedMessage id="EditListingFeaturesForm.practicalInfo.greeting" />
          </h2>

          <FieldTextInput
            className={css.features}
            id="eventTypes"
            name="eventTypes"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avTechEventTypesKeyMessage'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.avTechEventTypesPlaceholder'
            })}
          />

          <FieldSelect
            className={css.features}
            name="ownEquipment"
            id="ownEquipment"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.ownEquipmentKeyMessage'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldTextInput
            className={css.features}
            id="avEquipmentSupplied"
            name="avEquipmentSupplied"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avEquipmentKeyMessage'
            })}
          />

          <FieldTextInput
            className={css.features}
            id="avEquipment "
            name="avEquipment"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avTechEquipmentKeyMessage'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.avTechEquipmentPlaceholder'
            })}
          />

          <FieldTextInput
            className={css.features}
            id="avCertifications"
            name="avCertifications"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avTechCertificationsKeyMessage'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.avTechCertificationsPlaceholder'
            })}
          />

          <FieldTextInput
            className={css.features}
            id="references"
            name="references"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.referencesKeyMessage'
            })}
          />

          <FieldSelect
            className={css.features}
            name="avSetupAndLive"
            id="avSetupAndLive"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avSetupAndLiveKeyMessage'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.features}
            name="preConsultation"
            id="preConsultation"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.preConsultationKeyMessage'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.features}
            name="venueEquipment"
            id="venueEquipment"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.venueEquipmentKeyMessage'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldTextInput
            className={css.features}
            id="avSoftware"
            name="avSoftware"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avSoftwareKeyMessage'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.avSoftwarePlaceholder'
            })}
          />

          <FieldTextInput
            className={css.features}
            id="avInsurance"
            name="avInsurance"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avInsuranceKeyMessage'
            })}
          />

          <FieldSelect
            className={css.features}
            name="travel"
            id="travel"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.travelKeyMessage'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldTextInput
            className={css.features}
            id="onsiteTroubleShooting"
            name="onsiteTroubleShooting"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.onsiteTroubleShootingKeyMessage'
            })}
          />

          <FieldTextInput
            className={css.features}
            id="avEnsureExperience"
            name="avEnsureExperience"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avEnsureExperienceKeyMessage'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.onsiteTroubleShootingPlaceholderMessage'
            })}
          />

          <FieldSelect
            className={css.features}
            name="avSpecialSetup"
            id="avSpecialSetup"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avSpecialSetupKeyMessage'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldTextInput
            className={css.features}
            id="language"
            name="language"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.language'
            })}
          />

          <FieldTextInput
            className={css.features}
            id="avPostEvent"
            name="avPostEvent"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.avPostEventKeyMessage'
            })}
          />
        </div>
      </div>
    )
  }
}
EditAVTechFeaturesComponent.defaultProps = {
  filterConfig: custom.filters
}
EditAVTechFeaturesComponent.propTypes = {
  filterConfig: propTypes.filterConfig,
  intl: intlShape.isRequired
}

export default injectIntl(EditAVTechFeaturesComponent)
